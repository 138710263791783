/* eslint-disable */
import moment from 'moment';
import {bus} from '@/main';  
import { mapGetters } from 'vuex'; 
import updateAppointmentDetails from '@/components/private/update-appointment-details-modal';
import appointmentMakeFav from '@/components/private/appointment-make-favourite-popup';
import remarksListPopup from '@/components/private/remarks-list-pooup';
export default {
  name: 'appointment',
  components: {
    'update-appointment-details' : updateAppointmentDetails,
    'appointment-favourite' : appointmentMakeFav,
    'remarks-list' : remarksListPopup
  },
  props: [],
  data () {
    return {
      appointmentListState:{},
      updateAppointmentDetailModal:false,
      updateAppointmentId:'',
      appointmentInfoForFav:null,
      appointmentFavModal:false,
      remarksListPopupState:false,
      remarksListPopupData:null
    }
  },
  computed: {
    ...mapGetters([
      'appointmentListGetters'
    ]),
    getAppointmentListMethod() {
      return this.appointmentListState = this.$store.getters.appointmentListGetters.data
    },
  },
  mounted () {
    this.getAppointmentListMethod
    bus.$on('updateAppointmentDetailModalBus', (data) => {
      this.updateAppointmentDetailModal = data
    });
    bus.$on('appointmentFavModalBus', (data) => {
      this.appointmentFavModal = data
    });
    bus.$on('remarksListPopupStateBus', (data) => {
      this.remarksListPopupState = data
    });
  },
  methods: {
    remarkPopupOpenMethod(data){
      this.remarksListPopupData = data
      this.remarksListPopupState = true
      document.body.classList.add('popup_open_body')
    },
    rescheduleAppointmentCheckMethod(date){
      var currentLocalDate = moment().format('MM D YYYY');
      var startDateCheck = moment(date).format('MM D YYYY')
      if(startDateCheck > currentLocalDate){
        return true;
      }else{
        return false;
      }
    },
    updateAppointmentDetailModalMethod(id){
      document.body.classList.add('popup_open_body')
      this.updateAppointmentDetailModal = true
      this.updateAppointmentId = id
    },
    makeAppointmentFavouriteMethod(data){
      document.body.classList.add('popup_open_body')
      this.appointmentInfoForFav = data
      this.appointmentFavModal = true
    }
  },
  watch:{
    getAppointmentListMethod() {
      return this.$store.getters.appointmentListGetters
    },
  }
}


